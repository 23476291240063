// Transform to from crop to rect
// crop: left, top, bottom, right
// rect: x, y, w, h
// x =  width * left
// y = height * top
// w = width - right  * width - (width * left)

import groq from 'groq';
import {
  SanityImageAsset,
  SanityImageCrop,
  SanityReference,
  SanityImageHotspot,
} from '../schemaTypes';

// h = height - bottom * height - (height * top)

export type CroppedImageUrl = string | null | undefined;
export const croppedImageUrlProjection = groq`
   asset->{...}.url 
    + "?rect="
    + string(round(asset->{...}.metadata.dimensions.width * crop.left)) + "," // x
    + string(round(asset->{...}.metadata.dimensions.height * crop.top)) + "," // y
    + string(round(
        asset->{...}.metadata.dimensions.width
        - crop.right
        * asset->{...}.metadata.dimensions.width
        - (asset->{...}.metadata.dimensions.width * crop.left)
    )) + "," // w
    + string(round(
        asset->{...}.metadata.dimensions.height
        - crop.bottom
        * asset->{...}.metadata.dimensions.height
        - (asset->{...}.metadata.dimensions.height * crop.top)
    )) // h 
    + "&w=5000&fit=max"
`;

export type MaterializedImage = {
  _type: 'image';
  asset?: SanityReference<SanityImageAsset>;
  width: number | null;
  height: number | null;
  lqip: string | null;
  url: string | null;
  caption: string | null;
  alt: string | null;
  hotspot?: SanityImageHotspot | null;
};

export type MaterializedImageWithCrop = MaterializedImage & {
  crop: SanityImageCrop;
  croppedImageUrl: CroppedImageUrl;
};

export const imageProjection = groq`
  "width": asset->metadata.dimensions.width,
  "height": asset->metadata.dimensions.height,
  "lqip": asset->metadata.lqip,
  "url":asset->url,
  caption,
  alt
`;

export const imageWithCropProjection = groq`
  ${imageProjection},
  crop {...},
  "croppedImageUrl": asset->.url,
  defined(crop) => {
    "croppedImageUrl": ${croppedImageUrlProjection},
  }
`;
