import groq from 'groq';
import {
  internalLinkProjection,
  MaterializedInternalLink,
} from '../../materializedTypes';
import { CampaignCardV3, SanityKeyed } from '../../schemaTypes';

export type MaterializedCompaignCardV3 = SanityKeyed<
  CampaignCardV3 & {
    internalLink?: MaterializedInternalLink;
  }
>;

export const campaignCardV3Projection = groq`
  ...,
  internalLink {${internalLinkProjection}},
  slots[] {
    ...,
    modules[] {
      ...,
      _type == 'video' => {
        video {
          asset-> {...}
        }
      }
    }
  }
`;
