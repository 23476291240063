import { configuredSanityClient } from '@/lib/sanity/sanityClient';
import groq from 'groq';
import {
  imageWithCropProjection,
  internalLinkProjection,
  MaterializedImageWithCrop,
  MaterializedInternalLink,
  NavItem,
} from '@fagmobler/sanity';

export type TSearchMenuItem = {
  category?: string;
  title: string;
  slug: string;
};

export type TNavigationItem = {
  showInHeader?: boolean;
  title: string;
  _key: string;
  _type: string;
  children?: TNavigationItem[];
  image?: MaterializedImageWithCrop;
  internalLink?: MaterializedInternalLink;
  navType: NavItem['navType'];
  navLayout: NavItem['navLayout'];
  objectFit: NavItem['objectFit'];
};

export type TNavigationInspirationItem = {
  _key: string;
  title: string;
  path: string;
  image: MaterializedImageWithCrop;
};

export type TNavigationBannerItem = {
  _key: string;
  title?: string;
  linkLabel: string;
  path: string;
  image?: MaterializedImageWithCrop;
  backgroundColor?: string;
};

export type TNavigation = {
  mainNavigation: TNavigationItem[];
  menuInspiration: TNavigationInspirationItem[];
  menuBanners: TNavigationBannerItem[];
  footer: TNavigationItem[];
};

const navItemQuery = groq`
  defined(showInHeader) => {showInHeader},
  title,
  _type,
  _key,
  navType,
  "navLayout": coalesce(navLayout, 'list'),
  "objectFit": coalesce(objectFit, 'contain'),
  showInHeader,
  image{
    ${imageWithCropProjection}
  },
  defined(internalLink) => {
    internalLink {
      ${internalLinkProjection}
    }
  }
`;

export const getNavigation = async (): Promise<TNavigation> => {
  const data = await configuredSanityClient.fetch(
    groq`*[_id == "siteSettings"] {
        mainNavigation[] {
          ${navItemQuery},
          defined(children) => {children[] {
            ${navItemQuery},
            defined(children) => {children[] {
              ${navItemQuery},
              defined(children) => {children[] {
                ${navItemQuery}
              }}
            }}
          }}
        },
        menuInspiration[]->{
          "_key": _id,
          title,
          "path": '/inspirasjon/' + slug.current,
          "image": mainImage{
            ${imageWithCropProjection}
          }
        },
        menuBanners[]{
          "_key": page->_id,
          title,
          linkLabel,
          "path": page->slug.current,
          "backgroundColor": background.backgroundColor,
          "image": background.image{
            ${imageWithCropProjection}
          }
        },
        footer[] {
          ${navItemQuery},
          defined(children) => {
            children[] {
              ${navItemQuery}
            }
          }
        },
        searchMenu[] {
          ...coalesce(@->, @) {
            category,
            title,
            'slug': coalesce(slug.current, slug)
          }
        }
      }[0]`
  );
  return data as TNavigation;
};
