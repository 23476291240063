import groq from 'groq';
import type { ContentBlocks as TContentBlocks } from '../../schemaTypes';
import {
  imageWithCropProjection,
  imageWithPointsProjection,
  internalLinkProjection,
} from '../../materializedTypes';
import { MaterializedArticleCard, articleCardProjection } from './articleCard';
import {
  MaterializedCompaignCard,
  campaignCardProjection,
} from './campaignCard';
import {
  MaterializedCategoryCollectionCard,
  categoryCollectionProjection,
} from './categoryCollection';
import { MaterializedContentCard, contentCardProjection } from './contentCard';
import {
  MaterializedImageCollectionBlock,
  imageCollectionProjection,
} from './imageCollection';
import { MaterializedMemberCard, memberCardProjection } from './memberCard';
import {
  MaterializedProductCollectionBlock,
  productCollectionProjection,
} from './productCollection';
import { TextboxCardBlock, textboxCardProjection } from './textboxCard';
import { videoProjection, MaterializedVideo } from './video';
import {
  imageAndTextProjection,
  MaterializedImageAndTextBlock,
} from './imageAndText';
import { MaterializedReusableContentBlock } from '../reusableContentBlock';
import { MaterializedImageWithPointsDocument } from '../../documents';
import { SimpleProductCategoryEnrichment } from './productCategoryEnrichment';
import {
  MaterializedContentBlocksGrid,
  contentBlocksGridProjection,
} from './contentBlocksGrid';
import {
  MaterializedCompaignCardV2,
  campaignCardV2Projection,
} from './campaignCardV2';
import { MaterializedTopBanner, topBannerProjection } from './topBanner';
import {
  MaterializedCampaignBanner,
  campaignBannerProjection,
} from './campaignBanner';
import {
  MaterializedFullWidthCampaignBanner,
  fullWidthCampaignBannerProjection,
} from './fullWidthCampaignBanner';
import {
  campaignTabsProjection,
  MaterializedCampaignTabs,
} from './campaignTabs';
import {
  campaignCardV3Projection,
  MaterializedCompaignCardV3,
} from './campaignCardV3';

export type ContentBlock = TContentBlocks[0];

export type MaterializedContentBlock = (
  | MaterializedContentBlocksGrid
  | MaterializedImageWithPointsDocument
  | MaterializedArticleCard
  | MaterializedCategoryCollectionCard
  | MaterializedCompaignCard
  | MaterializedCompaignCardV2
  | MaterializedContentCard
  | MaterializedProductCollectionBlock
  | MaterializedMemberCard
  | MaterializedImageCollectionBlock
  | TextboxCardBlock
  | MaterializedReusableContentBlock
  | MaterializedVideo
  | MaterializedImageAndTextBlock
  | SimpleProductCategoryEnrichment
  | MaterializedTopBanner
  | MaterializedCampaignBanner
  | MaterializedFullWidthCampaignBanner
  | MaterializedCampaignTabs
  | MaterializedCompaignCardV3
) & {
  _key: string;
  _type: string;
};

export const contentBlockProjection = groq`
  _type == "contentBlocksGrid" => {${contentBlocksGridProjection}},
  _type == "image" => {${imageWithCropProjection}},
  _type == "imageAndText" => {${imageAndTextProjection}},
  _type == "articleCard" => {${articleCardProjection}},
  _type == "memberCard" => {${memberCardProjection}},
  _type == "campaignCard" => {${campaignCardProjection}},
  _type == "campaignCardV2" => {${campaignCardV2Projection}},
  _type == "campaignCardV3" => {${campaignCardV3Projection}},
  _type == "contentCard" => {${contentCardProjection}},
  _type == "video" => {${videoProjection}},
  _type == "imageCollection" => {${imageCollectionProjection}},
  _type == 'categoryCollection' => {${categoryCollectionProjection}},
  _type == 'productCollection' => {${productCollectionProjection}},
  _type == 'textboxCard' => {${textboxCardProjection}},
  _type == 'imageWithPoints' => {${imageWithPointsProjection}},
  _type == 'topBanner' => {${topBannerProjection}},
  _type == 'campaignBanner' => {${campaignBannerProjection}},
  _type == 'fullWidthCampaignBanner' => {${fullWidthCampaignBannerProjection}},
  _type == 'campaignTabs' => {${campaignTabsProjection}}
`;
